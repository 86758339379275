
import { Component, Prop, ProvideReactive, mixins, Action, Getter } from "nuxt-property-decorator"
import { MetaInfo } from "vue-meta"
import ImageUrlS3 from "~/mixins/imageUrlS3"
import { SET_BREADCRUMBS } from "~/store/pages/actions"
import { SET_FW_TOKEN, SET_USER } from "~/store/auth/actions"
import { AuthenticationService } from "~/services/public/Authentication"
import { TrackingService } from "~/services/public/Tracking"
import { PhoneNumberService } from "~/services/public/PhoneNumber"
import { GET_FW_TOKEN } from "~/store/auth/getters"
import { CreditCodeService } from "~/services/public/CreditCode"
import { SET_CREDIT_CODE_VALUE } from "~/store/credit-code/actions"
import { SET_PHONE_NUMBER_VALUE } from "~/store/phone-number/actions"
import { SET_LOCATION_INFO, SET_MAP_LOCATIONS } from "~/store/location/actions"
import { SET_INTERNAL_REVIEWS } from "~/store/internal-reviews/mutations"
import { buildStructuredData } from "~/scripts/google-structured-data"

@Component
export default class PageLayout extends mixins(ImageUrlS3) {
  @Prop({ required: true }) page: Page

  @Action(SET_BREADCRUMBS, { namespace: "pages" }) setBreadcrumbs: Function
  @Action(SET_MAP_LOCATIONS, { namespace: "location" }) setMapLocations: Function
  @Action(SET_LOCATION_INFO, { namespace: "location" }) setLocationInfo: Function
  @Action(SET_INTERNAL_REVIEWS, { namespace: "internal-reviews" }) setInternalReviews: Function

  @ProvideReactive("internalReviewsFilter") internalReviewsFilter: Record<string, any>

  layout: string = ""

  async fetch() {
    await this.setBreadcrumbs({
      breadcrumbs: this.page!.seo!.breadcrumbs
    })
  }

  getLayoutFromTemplate() {
    let template = this.page?.template?.templateName
      .trim()
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))

    if (
      ![
        "Advice",
        "Article",
        "Avb",
        "Blank",
        "Blog",
        "Default",
        "RemovalsHub",
        "RightSidebar",
        "StaticDocument",
        "Rebrand"
      ].includes(template)
    ) {
      template = "Default"
    }

    return "PageLayout" + template
  }

  created() {
    const hasLatLongs =
      this.page &&
      this.page.location &&
      this.page?.location?.mapPinsLatitude &&
      this.page?.location?.mapPinsLongitude

    if (hasLatLongs) {
      this.setMapLocations(this.page.location)
      this.setLocationInfo(this.page.location)
    }

    if (this.page?.pageInternalReviews) {
      this.setInternalReviews(this.page.pageInternalReviews)
    }

    this.layout = this.getLayoutFromTemplate()
    this.internalReviewsFilter = this.page.internalReviews
  }

  async mounted() {
    const query = this.$route.query
    query.utm_media =
      query.utm_media || (this.page!.seo!.metaRobotsNoindex === "index" ? "seo" : "ppc")

    await this.authenticateAndUpdatePhone()
    await this.sendTrackingEvents()
    await this.useCreditCode()
    buildStructuredData(this.page)
  }

  head(): MetaInfo {
    let head: MetaInfo = {}

    if (this.page) {
      const seo = this.page.seo
      const frontendUrl = this.page.frontendUrl

      const facebookTags = [
        ...(seo?.opengraphType
          ? [
              {
                property: "og:type",
                content: seo.opengraphType
              }
            ]
          : []),
        ...(seo?.opengraphTitle
          ? [
              {
                property: "og:title",
                content: seo.opengraphTitle
              }
            ]
          : []),
        ...(seo?.opengraphDescription
          ? [
              {
                property: "og:description",
                content: seo.opengraphDescription
              }
            ]
          : []),
        ...(frontendUrl
          ? [
              {
                property: "og:url",
                content: frontendUrl
              }
            ]
          : []),
        ...(seo?.opengraphImage?.mediaDetails?.sizes?.length > 0
          ? [
              {
                property: "og:image",
                content: this.replaceUrlS3(
                  seo?.opengraphImage?.mediaDetails.sizes.find(s => s.name === "thumbnail")
                    ?.sourceUrl
                )
              }
            ]
          : [])
      ]

      const twitterTags = [
        ...(seo?.twitterTitle
          ? [
              {
                property: "twitter:title",
                content: seo.twitterTitle
              }
            ]
          : []),
        ...(seo?.twitterDescription
          ? [
              {
                property: "twitter:description",
                content: seo.twitterDescription
              }
            ]
          : []),
        ...(seo?.twitterImage?.mediaDetails?.sizes?.length > 0
          ? [
              {
                property: "twitter:image",
                content: this.replaceUrlS3(
                  seo?.twitterImage?.mediaDetails.sizes.find(s => s.name === "thumbnail")?.sourceUrl
                )
              }
            ]
          : [])
      ]

      head = {
        link: [],
        meta: [
          {
            name: "robots",
            content:
              this.$config.appName === ""
                ? "noindex, nofollow"
                : seo?.metaRobotsNoindex + ", " + seo?.metaRobotsNofollow
          },
          {
            hid: "description",
            name: "description",
            content: seo ? seo.metaDesc : ""
          },
          ...facebookTags,
          ...twitterTags
        ],
        script: buildStructuredData(this.page)
          .filter(Boolean)
          .map(jsonData => ({
            type: "application/ld+json",
            json: jsonData as any
          })),
        title: this.page.title
      }
    }

    if (head.link) {
      if (
        [
          "PageLayoutAdvice",
          "PageLayoutBlog",
          "PageLayoutDefault",
          "PageLayoutRightSidebar"
        ].includes(this.layout) &&
        this.page?.featuredImage?.node
      ) {
        head.link.push({
          // @ts-ignore: in Array
          rel: "preload",
          // @ts-ignore: in Array
          as: "image",
          // @ts-ignore: in Array
          href: this.replaceUrlS3(this.page.featuredImage.node.sourceUrl)
        })
      }

      const canonical = this.page!.seo!.canonical

      if (canonical && this.$config.frontendHost) {
        head.link.push({
          // @ts-ignore: in Array
          rel: "canonical",
          // @ts-ignore: in Array
          href: canonical.includes(this.$config.cmsHost)
            ? canonical.replace(this.$config.cmsHost, this.$config.frontendHost).replace(/\/$/, "")
            : canonical.replace(/\/$/, "")
        })
      }
    }

    return head
  }

  @Action(SET_FW_TOKEN, { namespace: "auth" }) setFwToken: Function
  @Action(SET_USER, { namespace: "auth" }) setUser: Function
  @Getter(GET_FW_TOKEN, { namespace: "auth" }) nonce: string

  private async authenticateAndUpdatePhone(): Promise<void> {
    const auth = new AuthenticationService(this.$axios, this.$config)
    const profile = await auth.getProfile()
    if (profile?.fw_token) {
      this.setFwToken(profile.fw_token)
      await this.updatePhoneNumber()
    }
    if (profile?.user) {
      this.setUser(profile.user)
    }
  }

  @Action(SET_PHONE_NUMBER_VALUE, { namespace: "phone-number" }) setPhoneNumberValue: Function

  private async updatePhoneNumber(): Promise<void> {
    const phoneService = new PhoneNumberService(this.$axios, this.$config)
    // @ts-ignore -- Page is there by default.
    const phoneNumber: PhoneNumberPair = await phoneService.updatePhoneNumber(
      this.page,
      this.$route,
      this.nonce
    )
    this.setPhoneNumberValue(phoneNumber)

    const trackingData: CallUsPayload = {
      phoneNumber: phoneNumber.base,
      label: "lego_phone_number",
      action: "viewed"
    }
    if (phoneNumber.extension !== "") {
      // @ts-ignore
      trackingData.extension = phoneNumber.extension
    }
    this.$snowplow.trackCallUs(trackingData)
  }

  @Action(SET_CREDIT_CODE_VALUE, { namespace: "credit-code" }) setCreditCodeValue: Function

  private async useCreditCode(): Promise<void> {
    const creditCodeService = new CreditCodeService(this.$axios, this.$config)
    const creditCodeResponse = await creditCodeService.getCreditCodeValue(this.page, this.$route)
    if (creditCodeResponse && creditCodeResponse["value"]) {
      this.setCreditCodeValue(parseInt(creditCodeResponse["value"]))
    }
  }

  private async sendTrackingEvents(): Promise<void> {
    const trackingService = new TrackingService(this.$axios, this.$config)
    const response = await trackingService.postTrackingEvent(
      { label: "LEGO - " + this.$route.path },
      this.$route,
      this.$cookies,
      this.page,
      window
    )

    if (response && response.data && response.data.track_id) {
      this.$snowplow.trackAnyVanTrackId(response.data.track_id)
    }
  }
}
